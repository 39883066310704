import { Alert, Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getContainerByScan, postContainerScan } from 'src/api/outbound/shipping_api';
import { useBoolean } from 'src/hooks/use_boolean';
import { isEmpty } from 'src/utils/type_check';
import ScanCard from 'src/components/cards/scan_card';

const ShippingContainersScanView = ({ selectedLocation }) => {
  const { t } = useTranslation(['shipping']);
  const [orderShipmentId, setOrderShipmentId] = useState('');
  const [containerId, setContainerId] = useState(null);
  const [shippedRes, setShippedRes] = useState({});
  const showAlert = useBoolean();
  const noOrder = useBoolean(true);
  const noContainer = useBoolean(true);
  const onScan = async (search) => {
    if (isEmpty(search)) return;
    if (noOrder.value) {
      showAlert.onFalse();
      setContainerId('');
      await fetchOrderShipmentId(search);
    } else {
      await submitContainerScan(search);
    }
  };

  const fetchOrderShipmentId = async (search) => {
    setOrderShipmentId('');
    try {
      const res = await getContainerByScan(selectedLocation, search);
      if (res.isSuccess) {
        setOrderShipmentId(res.result?.orderShipment?.orderShipmentId || '');
        noOrder.onFalse();
        noContainer.onTrue();
      }
      setShippedRes(res);
    } catch (error) {
      console.error('Error fetching order shipment ID:', error);
      setShippedRes({ isSuccess: false, errorMessages: [t('shipping:api_error')] });
    } finally {
      showAlert.onTrue();
    }
  };

  const submitContainerScan = async (search) => {
    setContainerId('');
    try {
      const data = {
        orderShipmentId: orderShipmentId,
        shipmentContainerNumber: search,
      };
      const res = await postContainerScan(data);
      setShippedRes(res);
      noContainer.onFalse();
      noOrder.onTrue();
      setOrderShipmentId('');
      setContainerId(search);
    } catch (error) {
      console.error('Error submitting container scan:', error);
      setShippedRes({ isSuccess: false, errorMessages: [t('shipping:api_error')] });
    } finally {
      showAlert.onTrue();
    }
  };

  const renderAlert = () => {
    return (
      <Box sx={{ pt: 1 }}>
        <Alert
          variant={'outlined'}
          onClose={showAlert.onFalse}
          severity={shippedRes?.isSuccess ? 'success' : 'error'}
          sx={{ minWidth: '400px', maxWidth: '100%' }}
        >
          {shippedRes?.isSuccess ? (
            noContainer.value ? (
              <>
                <Typography variant="heading_sm">
                  {t('common:order-', {
                    orderNumber: shippedRes?.result?.orderShipment?.orderNumber,
                  })}
                </Typography>
                <Typography variant="body_md">{t('shipping:containers.order_ready')}</Typography>
              </>
            ) : (
              <Typography variant="body_md">
                {t('shipping:containers.container_added', { container: containerId })}
              </Typography>
            )
          ) : (
            <Typography variant="body_md">{shippedRes?.errorMessages[0]}</Typography>
          )}
        </Alert>
      </Box>
    );
  };

  const renderInitialScanner = (
    <ScanCard
      onEnter={onScan}
      image="/images/container_scan.png"
      width={60}
      height={60}
      heading={t('shipping:containers.please_scan')}
      body={t('shipping:containers.please_scan_text')}
      label={
        noOrder.value
          ? t('shipping:containers.scan_tr_number')
          : t('shipping:containers.scan_container_number')
      }
      placeholder={
        noOrder.value
          ? t('shipping:containers.scan_tr_number')
          : t('shipping:containers.scan_container_number')
      }
      node={showAlert.value ? renderAlert() : null}
    />
  );

  return <Box>{renderInitialScanner}</Box>;
};

export default ShippingContainersScanView;
