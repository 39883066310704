import { memo } from 'react';
import Stack from '@mui/material/Stack';
import NavList from './nav_list';

function NavSectionVertical({ data, sx, currentNav }) {
  const renderContent = data.map((list, index) => (
    <NavList
      key={index}
      data={list}
      depth={0}
      hasChild={!!list.children}
      isSelected={currentNav}
    />
  ));

  return <Stack sx={sx}>{renderContent}</Stack>;
}

export default memo(NavSectionVertical);
