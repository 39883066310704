import { useState, useEffect, useCallback } from 'react';
import Collapse from '@mui/material/Collapse';
import NavItem from './nav_item';
import { usePathname } from 'src/hooks/router/use_pathname';
import { useActiveLink } from 'src/hooks/router/use_active_link';

export default function NavList({ data, depth, hasChild, isSelected }) {
  const pathname = usePathname();
  let active = useActiveLink(data.url, true);
  if (isSelected) {
    active = isSelected === data.key;
  }

  const [open, setOpen] = useState(active);
  useEffect(() => {
    if (!active && !isSelected) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, active]);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = () => {
    if (data.url) {
      handleToggle();
    }
    if (data.onClick) {
      data.onClick();
    }
  };

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={open}
        active={active}
        onClick={handleClick}
      />
      {hasChild && (
        <Collapse
          in={open}
          unmountOnExit
        >
          <NavSubList
            data={data.children}
            depth={depth}
            isSelected={isSelected}
          />
        </Collapse>
      )}
    </>
  );
}

function NavSubList({ data, depth, isSelected }) {
  return (
    <>
      {data.map((list, index) => (
        <NavList
          key={index}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
          isSelected={isSelected}
        />
      ))}
    </>
  );
}
