import NavItem from './nav_item';
import { useActiveLink } from 'src/hooks/router/use_active_link';

export default function NavList({ data, isSelected }) {
  let active = useActiveLink(data.url, true);
  if (isSelected) {
    active = isSelected === data.key || isSelected.startsWith(data.key);
  }

  return (
    <NavItem
      item={data}
      active={active}
    />
  );
}
