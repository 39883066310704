import React, { useMemo, useState } from 'react';
import StackedModal from 'src/components/dialog/stacked_modal';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Alert } from '@mui/material';
import { toteFormSchema } from 'src/utils/schema';
import CommonSelect from 'src/components/hook_form/common_select';
import FormProvider from 'src/components/hook_form/form_provider';
import RHFTextField from 'src/components/hook_form/rhf_text_field';
import { InlineGrid } from '@shopify/polaris';
import { createRackOrTote, useGetLocations } from 'src/api/location_api';
import { RHFAPISelect } from 'src/components/hook_form/rhf_select';
import { TOTE_TYPES } from 'src/api/common_code/location_management';
import { requiredField } from 'src/utils/format_data';
import { chooseLan } from 'src/utils/language_code';

const CreateToteModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation(['settings']);
  const [errorMsg, setErrorMsg] = useState('');

  const handleModalClose = () => {
    onClose();
    reset();
    setErrorMsg('');
  };

  const defaultValues = useMemo(
    () => ({
      locationId: '',
      toteNumber: '',
      toteType: '',
      description: '',
      quantity: 1,
    }),
    [],
  );
  const methods = useForm({
    resolver: yupResolver(toteFormSchema(t)),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    setErrorMsg('');
    try {
      var res = await createRackOrTote(data, 'tote');
      if (res.isSuccess) {
        handleModalClose();
      } else {
        setErrorMsg(res.errorMessages[0] ?? '');
      }
    } catch (error) {
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const renderFormContent = (
    <Stack spacing={1}>
      {!!errorMsg && (
        <Alert
          severity="error"
          onClose={() => setErrorMsg('')}
        >
          {errorMsg}
        </Alert>
      )}
      <InlineGrid
        columns={2}
        gap="200"
      >
        <RHFAPISelect
          name="locationId"
          displayName={requiredField(t('common:field.location'))}
          toOption={(r) => ({
            key: r.locationId,
            value: chooseLan(r.locationNameLocal, r.locationNameEnglish),
          })}
          useOptions={useGetLocations}
          params={{
            pageNumber: 1,
            pageSize: 100,
            isActive: true,
          }}
        />
        <RHFTextField
          name="toteNumber"
          displayName={requiredField(t('common:field.tote_number'))}
        />
      </InlineGrid>
      <InlineGrid
        columns={3}
        gap="200"
      >
        <CommonSelect
          data={TOTE_TYPES}
          name="toteType"
          displayName={t('common:field.tote_type')}
        />
        <RHFTextField
          name="quantity"
          displayName={requiredField(t('common:field.tote_quantity'))}
          type="number"
          InputProps={{ inputProps: { min: 1, max: 200 } }}
        />
        <RHFTextField
          name="description"
          displayName={t('common:field.tote_description')}
        />
      </InlineGrid>
    </Stack>
  );

  const renderForm = (
    <FormProvider
      methods={methods}
      onSubmit={onSubmit}
    >
      {renderFormContent}
    </FormProvider>
  );

  return (
    <StackedModal
      title={t('settings:locations_management.create_tote')}
      content={renderForm}
      isOpen={isOpen}
      onClose={handleModalClose}
      primaryAction={{
        content: t('common:create_and_print'),
        onAction: onSubmit,
        loading: isSubmitting,
      }}
      secondaryAction={{
        content: t('common:cancel'),
        onAction: handleModalClose,
      }}
    />
  );
};
export default CreateToteModal;
