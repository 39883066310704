import { Text, Box, InlineStack, Button, Tag, Badge, Popover, ActionList } from '@shopify/polaris';
import { ConnectIcon, MenuHorizontalIcon } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import Image from 'src/components/image/image';
import { useGetLocationCouriers } from 'src/api/mapping_api';
import { getLocationName } from 'src/utils/format_data';
import { chooseLan } from 'src/utils/language_code';
import { useState, useCallback } from 'react';
import Loading from '../loading';

export const LocationInfo = ({
  id,
  name,
  description,
  onInfoClick,
  onClick,
  actionTitle,
  connected,
  disabled,
}) => {
  const { t } = useTranslation(['common', 'settings']);

  return (
    <Box>
      <InlineStack
        blockAlign="center"
        align="space-between"
      >
        <Stack
          sx={{
            paddingLeft: 'var(--p-space-200)',
            flex: 'column',
            alignItems: 'flex-start',
            minHeight: '40px',
            justifyContent: 'center',
          }}
        >
          <button
            className="text-button"
            onClick={onInfoClick}
          >
            <Typography
              variant="heading_sm"
              color="link"
            >
              {name}
            </Typography>
          </button>
          {description && <Text variant="bodySm">{description}</Text>}
        </Stack>
        {!connected && !disabled ? (
          <Button
            onClick={onClick}
            icon={ConnectIcon}
          >
            {actionTitle}
          </Button>
        ) : (
          <Box className={`status-tag-wrapper ${connected ? 'success-bg' : 'disabled-bg'}`}>
            <Tag>{disabled ? t('common:deactivated') : t('common:connected')}</Tag>
          </Box>
        )}
      </InlineStack>
    </Box>
  );
};

export const LocationInfoCard = ({ id, loc, onInfoClick, userId, onDisconnect }) => {
  const { t } = useTranslation();
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const { searchResults: couriers = [], searchLoading: loading } = useGetLocationCouriers({
    locationId: id,
    isMapping: true,
  });

  if (loading) return <Loading />;
  return (
    <Box>
      <InlineStack
        blockAlign="center"
        align="space-between"
      >
        <Stack
          sx={{
            paddingLeft: 'var(--p-space-200)',
            flex: 'column',
            alignItems: 'flex-start',
            minHeight: '40px',
            justifyContent: 'center',
          }}
        >
          <button
            className="text-button"
            onClick={onInfoClick}
          >
            <Typography
              variant="heading_sm"
              color="link"
            >
              {chooseLan(loc.locationNameLocal, loc.locationNameEnglish)}
            </Typography>
          </button>
          <Text variant="bodySm">{getLocationName(loc) ?? t('settings:locations.no_address')}</Text>
        </Stack>
        <InlineStack
          gap={200}
          blockAlign="center"
        >
          {couriers.length > 0 ? (
            <InlineStack
              gap={200}
              blockAlign="center"
              align="center"
            >
              {couriers.map((c, index) => {
                return (
                  <Box
                    key={index}
                    background="bg-surface-success"
                    borderRadius="300"
                    paddingInlineStart={300}
                    padding={100}
                  >
                    <InlineStack gap="200">
                      <Typography
                        variant="heading_sm"
                        color="text.success"
                      >
                        {chooseLan(c.courierNameLocal, c.courierNameEnglish)}
                      </Typography>

                      <Image
                        src={c.representativeImageUrl}
                        alt={c.courierId}
                        sx={{
                          width: 28,
                          height: 28,
                          borderRadius: 'var(--p-border-radius-200)',
                        }}
                      />
                    </InlineStack>
                  </Box>
                );
              })}
            </InlineStack>
          ) : (
            <Badge tone="critical">{t('settings:locations.no_courier')}</Badge>
          )}
          <Popover
            active={popoverActive}
            activator={
              <Button
                icon={MenuHorizontalIcon}
                accessibilityLabel="Add theme"
                variant="tertiary"
                onClick={togglePopoverActive}
              />
            }
            autofocusTarget="first-node"
            onClose={togglePopoverActive}
            zIndexOverride={1500}
          >
            <ActionList
              items={[
                {
                  destructive: true,
                  content: t('common:disconnect'),
                  onAction: () => {
                    togglePopoverActive();
                    onDisconnect(userId, loc.locationId);
                  },
                },
              ]}
            />
          </Popover>
        </InlineStack>
      </InlineStack>
    </Box>
  );
};
