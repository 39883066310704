import { useState, useEffect } from 'react';
import {
  label,
  filters,
  headers,
  initialFilters,
  tabs,
  bulkAction,
  EmptyStateMarkup,
} from './loc_man_table_misc';
import rowMarkup from './loc_man_table_row';
import useTable from 'src/hooks/use_table';
import Table from 'src/components/table/table';
import { cleanParams } from 'src/utils/url';
import { useGetLocationsManagement } from 'src/api/location_api';
import { useTranslation } from 'react-i18next';
import { makeCombinedId } from 'src/utils/format_data';

export default function LocManTableView({
  selectedLocation,
  toggleToteModal,
  toggleRackView,
  type,
  setType,
  handleRowClick,
}) {
  const { t } = useTranslation(['settings']);
  const locationId = selectedLocation === 'all' ? '' : selectedLocation;
  const [params, setParams] = useState({
    pageSize: 20,
    pageNumber: 1,
    IsActive: true,
    locationId: locationId,
    locationType: '',
    temperatureType: '',
    q: '',
  });

  const { searchResults: data = {}, searchLoading } = useGetLocationsManagement(
    type,
    cleanParams(params),
  );

  const table = useTable({
    tabItems: tabs(data.count ?? {}, setType),
    totalDataCount: type === 'rack' ? data.count?.rackCount : data.count?.toteCount,
    data: data.list ?? [],
    headers: headers(type),
    rowMarkup: (props) => rowMarkup(props, type, handleRowClick),
    initialFilters: initialFilters(type),
    filters: (state, setState) => filters(state, setState, type),
    bulkAction: (data, selectedIds, handleSelectionChange) =>
      bulkAction(data, selectedIds, handleSelectionChange, type),
    disambiguateLabel: label,
    resourceIdResolver: (d) =>
      type === 'rack'
        ? makeCombinedId(d.rackNumber, d.locationId)
        : makeCombinedId(d.toteNumber, d.locationId),
    emptyStateMarkup: (
      <EmptyStateMarkup
        toggleToteModal={toggleToteModal}
        toggleRackView={toggleRackView}
        type={type}
      />
    ),
    searching: t('settings:locations_management.search_rack_number'),
    showSearchFilter: type === 'rack',
  });

  if (table.isRefetch.value) {
    setParams((prevParams) => ({
      ...prevParams,
      pageSize: table.rowsPerPage,
      pageNumber: table.page,
      locationId: locationId,
      locationType: table.filterValues.locationType,
      temperatureType: table.filterValues.temperatureType,
      q: table.debounceSearchQuery,
    }));
    table.onRefetched();
  }

  useEffect(() => {
    table.isRefetch.onTrue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  return (
    <Table
      useTable={table}
      isLoading={searchLoading}
    />
  );
}
