import { MenuItem, Typography, Select } from '@mui/material';
import { Box, InlineStack, Button } from '@shopify/polaris';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ArrowLeftIcon } from '@shopify/polaris-icons';
import { useGetLocations } from 'src/api/location_api';
import Loading from '../loading';
import { chooseLan } from 'src/utils/language_code';
import { useTranslation } from 'react-i18next';
import { useEffect, Fragment } from 'react';
import { useRouter } from 'src/hooks/router/use_router';

const LocationTitle = ({
  title,
  selectedLocation,
  handleLocationChange,
  primaryButton,
  secondaryButtons = [],
  includeAll = true,
  hasBottomPadding = true,
  backButtonUrl,
  backButton,
  setLocationName,
  setLocationUnit,
}) => {
  const { t } = useTranslation();
  const router = useRouter();

  const ALL = {
    locationId: 'all',
    locationNameLocal: t('common:all'),
    locationNameEnglish: t('common:all'),
  };
  const { searchResults: locations = [], searchLoading: loading } = useGetLocations({
    pageSize: 100,
    pageNumber: 1,
    isActive: true,
  });
  var selectLocations = includeAll ? [ALL, ...locations] : locations;

  useEffect(() => {
    if (selectedLocation === '' && locations.length > 0) {
      const defaultLocation = locations[0];
      setLocationName &&
        setLocationName(
          chooseLan(defaultLocation.locationNameLocal, defaultLocation.locationNameEnglish),
        );
      setLocationUnit && setLocationUnit({ dimensionUnit: defaultLocation.dimensionUnit });
      handleLocationChange(defaultLocation.locationId || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectLocations, selectedLocation]);

  const renderTitle = (
    <InlineStack
      align="space-between"
      blockAlign="start"
    >
      <Box paddingBlockEnd={hasBottomPadding ? 600 : 0}>
        <InlineStack blockAlign="center">
          {backButtonUrl && (
            <Button
              icon={ArrowLeftIcon}
              accessibilityLabel="Go back"
              onClick={() => router.push(backButtonUrl)}
              variant="tertiary"
            />
          )}
          {backButton && backButton}
          <Box paddingInline={200}>
            <Typography variant="heading_lg">{title}</Typography>
          </Box>
          <Select
            variant="standard"
            disableUnderline
            displayEmpty
            IconComponent={() => (
              <KeyboardArrowDownIcon
                fontSize="small"
                sx={{ position: 'absolute', right: 0, pointerEvents: 'none', color: 'icon' }}
              />
            )}
            renderValue={(selected) => (
              <Typography
                variant="heading_lg"
                color={'text.primary'}
              >
                {selected &&
                  chooseLan(
                    selectLocations.find((loc) => loc.locationId === selected)?.locationNameLocal,
                    selectLocations.find((loc) => loc.locationId === selected)?.locationNameEnglish,
                  )}
              </Typography>
            )}
            value={selectedLocation}
            onChange={(e) => {
              var selected = e.target.value;
              var selectedObj = selectLocations.find((loc) => loc.locationId === selected);
              setLocationName &&
                setLocationName(
                  chooseLan(selectedObj?.locationNameLocal, selectedObj?.locationNameEnglish),
                );
              setLocationUnit && setLocationUnit({ dimensionUnit: selectedObj?.dimensionUnit });
              handleLocationChange(selected);
            }}
          >
            {selectLocations.map((loc) => (
              <MenuItem
                key={loc.locationId}
                value={loc.locationId}
              >
                {chooseLan(loc.locationNameLocal, loc.locationNameEnglish)}
              </MenuItem>
            ))}
          </Select>
        </InlineStack>
      </Box>
      <InlineStack
        align="center"
        gap={200}
      >
        {secondaryButtons.map((btn, index) => (
          <Fragment key={index}>{btn}</Fragment>
        ))}
        {primaryButton}
      </InlineStack>
    </InlineStack>
  );

  return <div style={{ width: '100%' }}>{loading ? <Loading /> : renderTitle}</div>;
};

export default LocationTitle;
