import axiosInstance, { fetcher, revalidateOnlyStaleOptions } from 'src/utils/axios';
import { ENDPOINTS } from './endpoints';
import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { getURL } from 'src/utils/url';
import { isArray } from 'src/utils/type_check';
import { isEmpty } from 'lodash';

var currentURL = '';
var currentURLForLocMan = '';

export function useGetLocations(params) {
  if (isEmpty(params)) {
    params = {
      pageSize: 100,
      pageNumber: 1,
      locationId: '',
    };
  }
  const URL = getURL(ENDPOINTS.location.get_list, params);
  currentURL = URL;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result?.list || [],
      searchCount: {
        location: data?.result?.count.totalCount || 0,
      },
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}

export async function createLocation(data) {
  var ret = await axiosInstance.post(ENDPOINTS.location.post, data);
  await mutate(currentURL);
  return ret.data;
}

export async function editLocation(data, locationId) {
  const endpoint = ENDPOINTS.location.put(locationId);
  const response = await axiosInstance.put(endpoint, data);
  await mutate(currentURL);
  return response.data;
}

export async function deleteLocation(locationId) {
  const endpoint = ENDPOINTS.location.put(locationId);
  const response = await axiosInstance.delete(endpoint);
  await mutate(currentURL);
  return response.data;
}

export function useGetLocationsManagement(type, params) {
  const URL = getURL(ENDPOINTS.location.get_management_list(type), params);
  currentURLForLocMan = URL;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}

export function useGetRacks(params) {
  let URL = null;
  const locationId = params.locationId;

  if (!isEmpty(locationId)) {
    URL = getURL(ENDPOINTS.location.get_racks(locationId), params);
  }
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  if (URL === null) {
    return {
      searchResults: [],
      searchLoading: false,
      searchError: null,
      searchValidating: false,
    };
  }
  return memoizedValue;
}

export function useGetRackSlots(locationId, rackNumber, params) {
  let URL = null;
  if (!isEmpty(locationId) && !isEmpty(rackNumber)) {
    URL = getURL(ENDPOINTS.location.get_rack_info(locationId, rackNumber), params);
  }
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchSuccess: data?.isSuccess,
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, data?.isSuccess, error, isLoading, isValidating],
  );
  if (URL === null) {
    return {
      searchResults: [],
      searchLoading: false,
      searchError: null,
      searchValidating: false,
    };
  }
  return memoizedValue;
}

export async function getRackBarcode(locationId, rackBarcode) {
  const URL = ENDPOINTS.location.get_rack_barcode(locationId, rackBarcode);
  const res = await axiosInstance.get(URL);
  return res.data;
}

export async function createRackOrTote(data, type) {
  var isSuccess = false;
  var ret = await axiosInstance.post(ENDPOINTS.location.post_rack_or_tote(type), data);
  if (ret.data.isSuccess) {
    isSuccess = true;
  }
  if (isSuccess) mutate(currentURLForLocMan, (d) => d, true);
  return ret.data;
}

export async function editRack(data, locationId, rackNumber) {
  var isSuccess = false;
  const URL = ENDPOINTS.location.put_rack_info(locationId, rackNumber);
  var ret = await axiosInstance.put(URL, data);
  if (ret.data.isSuccess) {
    isSuccess = true;
  }
  if (isSuccess) mutate(ENDPOINTS.location.get_rack_info(locationId, rackNumber), (d) => d, true);
  return ret.data;
}

export async function deleteRackOrTote(ids, type) {
  if (!isArray(ids)) ids = [ids];
  var isSuccess = false;
  for (var i = 0; i < ids.length; i++) {
    var ret = await axiosInstance.delete(
      ENDPOINTS.location.delete_rack_or_tote(ids[i].id, ids[i].locationId, type),
    );
    if (ret.data.isSuccess) {
      isSuccess = true;
    }
  }
  if (isSuccess) mutate(currentURLForLocMan, (d) => d, true);
}

export function useGetLocation(id) {
  const { data, isLoading, error, isValidating } = useSWR(
    ENDPOINTS.location.get(id),
    fetcher,
    revalidateOnlyStaleOptions,
  );
  const memoizedValue = useMemo(
    () => ({
      searchLocation: data?.result?.location || {},
      searchCouriers: data?.result?.courierList || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}
