import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '@shopify/polaris';
import LocManTableView from 'src/sections/settings/location/table/loc_man_table_view';
import CreateToteModal from 'src/sections/settings/location/create_tote_modal';
import LocationTitle from 'src/components/title/location_title';
import { print } from 'src/utils/log';
import RackForm from './rack_form';
import { isEmpty } from 'src/utils/type_check';
import locationStore from 'src/stores/location_store';

const MODES = {
  VIEW: 'view',
  FORM: 'form',
};

const LocManagerView = () => {
  const { t } = useTranslation(['settings']);
  const [type, setType] = useState('rack');
  const [mode, setMode] = useState(MODES.VIEW);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rack, setRack] = useState({});
  const selectedLocation = locationStore((state) => state.getLocationId('locationManagement'));
  const { setLocationId: setSelectedLocation } = locationStore();

  const handleRowClick = (item) => {
    setRack(item);
    setMode(MODES.FORM);
  };

  const toggleCreateModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleView = useCallback(() => {
    setRack({});
    setMode(MODES.VIEW);
  }, []);

  const handleNewForm = useCallback(() => {
    setRack({});
    setMode(MODES.FORM);
  }, []);

  const renderTitle = (
    <LocationTitle
      handleLocationChange={(value) => setSelectedLocation('locationManagement', value)}
      selectedLocation={selectedLocation}
      title={t('settings:locations_management.loc_man_of')}
      hasBottomPadding={false}
    />
  );

  const renderModals = (
    <CreateToteModal
      isOpen={isModalOpen}
      onClose={toggleCreateModal}
    />
  );
  print('location_management load');

  const renderLocationManagementPage = () => {
    return mode === MODES.VIEW ? (
      <LocManTableView
        selectedLocation={selectedLocation}
        toggleToteModal={toggleCreateModal}
        toggleRackView={handleNewForm}
        type={type}
        setType={setType}
        handleRowClick={handleRowClick}
      />
    ) : (
      <RackForm
        onSuccess={() => setMode(MODES.VIEW)}
        rack={rack}
        onBack={handleView}
      />
    );
  };

  return (
    <>
      <Page
        fullWidth
        title={
          mode === MODES.FORM
            ? !isEmpty(rack)
              ? rack.rackNumber
              : t('settings:locations_management.create_rack')
            : renderTitle
        }
        primaryAction={
          mode === MODES.VIEW
            ? type === 'rack'
              ? {
                  content: t('settings:locations_management.create_rack'),
                  onAction: handleNewForm,
                }
              : {
                  content: t('settings:locations_management.create_tote'),
                  onAction: toggleCreateModal,
                }
            : null
        }
        backAction={mode === MODES.FORM && { content: t('common:Back'), onAction: handleView }}
      >
        {renderLocationManagementPage()}
        <div className="bottom-padding"></div>
      </Page>
      {renderModals}
    </>
  );
};

export default LocManagerView;
