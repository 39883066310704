import { Text, Box, InlineStack, Button, Avatar } from '@shopify/polaris';
import { EditIcon } from '@shopify/polaris-icons';
import { useGetMe } from 'src/api/user_api';
import { getUserInitial, getUserName } from 'src/utils/format_data';

const CustomerInfo = ({
  name,
  description,
  side,
  onClick,
  avatarSize = 'md',
  avatarPaddingRight = '16px',
}) => {
  const { user } = useGetMe();
  return (
    <Box
      padding={side ? '400' : '0'}
      background="bg-transparent"
    >
      <InlineStack
        blockAlign="center"
        align="space-between"
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ paddingRight: avatarPaddingRight }}>
            <Avatar
              size={avatarSize}
              name={getUserName(user)}
              initials={getUserInitial(user)}
            />
          </div>

          <Box>
            <Text
              variant="bodyMd"
              fontWeight="bold"
              as="h3"
            >
              {name}
            </Text>
            <Text
              breakWord={true}
              variant="bodySm"
            >
              {description}
            </Text>
          </Box>
        </div>
        {side ? null : (
          <InlineStack blockAlign="center">
            <Button
              variant="tertiary"
              onClick={onClick}
              icon={EditIcon}
            ></Button>
          </InlineStack>
        )}
      </InlineStack>
    </Box>
  );
};

export default CustomerInfo;
