import { ToastContainer } from 'react-toastify';
import Router from './routes/routes';
import ThemeProvider from './theme';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/build/esm/styles.css';
import './app.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import 'react-toastify/dist/ReactToastify.css';
import { MountPoint } from './components/dialog/confirmation';
import { findToken } from './utils/token';

function App() {
  findToken();
  return (
    <>
      <AppProvider i18n={enTranslations}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider>
            <MountPoint />
            <Router />
          </ThemeProvider>
        </LocalizationProvider>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </AppProvider>
    </>
  );
}

export default App;
